.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.global_menu { /* メニュー全体のスタイル */
  width: 100%; 
  position: relative;
  display: flex;
}
 
.child_menu { /* 下層メニューのスタイル */
  width: 300;
  position: absolute;
  top: 100%; /* 親項目の直下に配置 */
  display: flex;
  text-align: center;
}

circle
 {
  stroke-width: 250px;
  stroke-linecap: butt;
  fill: #fff;
}

.box {
  width: "100%";
  height: "100%";
  border-radius: 50%;
  background: var(--accent);
}

.wrap-original-transform {
  perspective: 2000px;
  transform-style: preserve-3d;
}

.original-transform {
  transform: rotateX(-30deg) rotateY(21deg) scale3d(0.95, 0.68, 1.23) skewX(-25deg);
}

.animation-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
}

.menu-item-link:hover {
  background-color: #edf9f09d;
  cursor: pointer;
}


.box1 {
  width: 150px;
  height: 150px;
  position: fixed;
  top: 25%;
  left: 85%;
  transform: translateX(-50%) translateY(-50%);
}

.box2 {
  width: 100px;
  height: 100px;
  position: fixed;
  top: 75%;
  left: 80%;
  transform: translateX(-50%) translateY(-50%);
}

.box3 {
  width: 150px;
  height: 100px;
  position: fixed;
  top: 15%;
  left: 15%;
  transform: translateX(-50%) translateY(-50%);
}

.box4 {
  width: 200px;
  height: 200px;
  position: fixed;
  top: 55%;
  left: 25%;
  transform: translateX(-50%) translateY(-50%);
}

.box5 {
  width: 130px;
  height: 100px;
  position: fixed;
  top: 60%;
  left: 90%;
  transform: translateX(-50%) translateY(-50%);
}

.box6 {
  width: 100px;
  height: 100px;
  position: fixed;
  top: 85%;
  left: 35%;
  transform: translateX(-50%) translateY(-50%);
}

.box7 {
  width: 150px;
  height: 150px;
  position: fixed;
  top: 85%;
  left: 10%;
  transform: translateX(-50%) translateY(-50%);
}

.box8 {
  width: 80px;
  height: 80px;
  position: fixed;
  top: 10%;
  left: 90%;
  transform: translateX(-50%) translateY(-50%);
}

.box9 {
  width: 200px;
  height: 200px;
  position: fixed;
  top: 90%;
  left: 90%;
  transform: translateX(-50%) translateY(-50%);
}

.box10 {
  width: 300px;
  height: 200px;
  position: fixed;
  top: 45%;
  left: 10%;
  transform: translateX(-50%) translateY(-50%);
}

.boxes {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.2);
}

.item {
  width: inherit;
  height: inherit;
  background: #EDF9F0;
  transform-origin: 50% 100%;
}

.background-animation-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.main-content {
  position: relative;
  z-index: 1;
}
